import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

// import Layout from "../components/Layout"
// import SEO from "../components/seo"

const NotFoundPage =(props)=> {
    const { data } = props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout>
        <SEO title="404: Not Found" />
        <h1>Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Layout>
    )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
